<template>
  <div id="widhdraw" v-if="page_show == 'main'">
    <van-nav-bar title="提现" right-text="提现记录" left-arrow @click-left="goLastPage" @click-right="goSubPage('withdraw-list')" scrollable />
    <!-- <van-notice-bar mode="closeable">每月前4次提现的手续费由万师傅为您代付,超过4次将按照2元/笔收取手续费</van-notice-bar> -->
    <div class="widhdraw-amount-wrap">
      <div class="widhdraw-type" @click="page_show = 'methods'">
        <p>提现方式:</p>
        <p style="marginLeft:1rem">{{selectedMethodsInfo.name}}
          <span style="marginLeft:1rem">
            <svg class="icon icon-rerset-withdraw-methods" aria-hidden="true" v-if="selectedMethodsInfo.icon">
              <use :xlink:href="selectedMethodsInfo.icon"></use>
            </svg>
          </span>
        </p>
        <p>
          <!-- <span v-if="selectedMethodsInfo.last_account.id"></span> -->
          <span v-if="selectedMethodsInfo.alipay_account"> {{selectedMethodsInfo.alipay_account}}</span>
          <span v-else>请选择提现方式</span>
          <van-icon name="arrow" />
        </p>
      </div>
      <div class="amount-form">
        <div class="input-wrap ">
          <input type="number" class="amount" v-model="amount">
          <span class="yuan">¥</span>
        </div>
        <div class="preview-amount">
          <p>可用余额: <span style="color:red;fontWeight:600">{{balance}}元</span> </p>
          <p @click="getAllBalance">全部提现</p>
        </div>
      </div>
    </div>
    <div class="widhdrawDescription">
      <p class="p0">提现次数说明</p>
      <p class="p1">
        1.每月前4次提现的手续费由万师傅为您代付,超过4次将按照2元/笔收取手续费
      </p>
      <p class="p1">
        2.发起提现视为您同意并且遵守 <span style="color: #ff7e79">提现服务协议</span>
      </p>
    </div>
    <van-submit-bar :disabled="disabled" :loading="false" :price="Number(amount * 100)" button-text="现在提现" @submit="onsubmitWidhdraw" label="实际到账" />
  </div>
  <widhdraw-methods-select @goMain="goMain" v-else @selected="getSelectedMethods" />
</template>
<script>
import WidhdrawMethodsSelect from "@/components/Mine/wallet/width-draw/SelectMethods";
export default {
  components: {
    WidhdrawMethodsSelect,
  },
  data() {
    return {
      selectedMethodsInfo: {},
      page_show: "main",
      amount: "",
      activeNames: [],
      balance: "",
      bank_card_arr: [],
      selected: {},
      alipay_name: "",
      alipay_num: "",
      payType: "",
      payTtitle3: "",
      payTtitle2: "",
      lastSelectedMethods: {},
      disabled: false,
      with_draw_list: [
        {
          id: 0,
          name: "银行卡",
          icon: "#icon--",
          route_name: "add-withdraw-methods-bank-card",
        },
        {
          id: 1,
          name: "微信",
          icon: "#icon-weixin1",
          route_name: "add-withdraw-methods-WeChat",
        },
        {
          id: 2,
          name: "支付宝",
          icon: "#icon-zhifubao1",
          route_name: "add-withdraw-methods-Alipay",
        },
      ],
    };
  },
  created() {
    // 获取余额
    this.$http.get("/api/v2/worker/wallet/balance").then((res) => {
      console.log(res);
      this.balance = res.data.result.balance;
      if (res.data.result.last_account.id != null) {
        this.selectedMethodsInfo = res.data.result.last_account;
      }
    });
    // 获取银行卡列表
    this.$http.get("/api/v2/worker/bank_card").then((res) => {
      console.log(res);
      this.bank_card_arr = res.data.cards;
    });
  },
  methods: {
    getSelectedMethods(e) {
      console.log(e);
      this.selectedMethodsInfo = e.selected;
      this.page_show = e.temp_link_main;
    },
    goMain() {
      this.page_show = "main";
    },
    getAllBalance() {
      this.amount = this.balance > 0 ? this.balance : "";
    },
    /**
     * 提现-支付宝
     */
    onsubmitWidhdraw() {
      if (this.amount.length == 0) {
        this.$toast("填写金额");
      } else if (!this.selectedMethodsInfo.id) {
        this.$toast("填选择提现方式");
      } else {
        this.$dialog
          .confirm({
            title: "是否确认提现？",
            message: "",
          })
          .then(() => {
            this.$http
              .post("/api/v2/worker/withdraw/with_alipay", {
                amount: this.amount,
                alipay_account_id: this.selectedMethodsInfo.id,
              })
              .then((res) => {
                this.$toast.success(res.data.message);
                this.goSubPage("withdraw-list");
              })
              .catch((err) => {
                this.$toast.fail(err.error);
              });
          })
          .catch(() => {
            // on cancel
          });
      }
    },
  },
};
</script>

<style lang="less">
#widhdraw {
  .widhdraw-amount-wrap {
    .icon-rerset-withdraw-methods {
      width: 2.5rem;
      height: 2.5rem;
    }
    margin: 2rem 1rem;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    box-sizing: border-box;
    border-radius: 10px;
    background: #fff8;
    .widhdraw-type {
      display: flex;
      font-size: 1.2rem;
      padding: 1.8rem 1rem;
      align-items: center;
      p:nth-child(1) {
        flex: 1;
        font-weight: 600;
      }
      p:nth-child(2) {
        flex: 1.5;
        display: flex;
        align-items: center;
      }
      p:nth-child(3) {
        flex: 3;
        text-align: right;
      }
    }
    .amount-form {
      .input-wrap {
        position: relative;
        input {
          width: 100%;
          border: none;
          border-bottom: 1px solid #f2f2f2;
          height: 8rem;
          font-size: 3.5rem;
          font-weight: 800;
          text-indent: 5rem;
          overflow: hidden;
        }
        .yuan {
          position: absolute;
          display: inline-block;
          width: 10%;
          font-family: iconfont;
          font-size: 2.4rem;
          left: 1.9rem;
          font-size: 3rem;
          bottom: 2.2rem;
          z-index: 9;
        }
      }

      .preview-amount {
        font-size: 1.4rem;
        display: flex;
        justify-content: space-between;
        padding: 1rem;
        background: #fff;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
      }
    }
  }
  .widhdrawAmount {
    position: relative;
    background: #fff;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    .tip {
      font-size: 1.3rem;
    }
    .sumMoney {
      display: flex;
      align-items: center;
      padding: 2rem 0 0.5rem 0;
      border: none;
      border-bottom: 1px solid #f2f2f2;
      .unit {
        display: inline-block;
        width: 10%;
        font-family: iconfont;
        font-size: 2.4rem;
      }
      .close {
        font-family: iconfont;
        font-size: 1.68rem;
        color: #7e7e7e;
        margin-left: 1.8rem;
      }
      input {
        height: 5rem;
        width: 45%;
        font-size: 3.8rem;
        font-weight: 600;
        border: none;
      }
      .all {
        position: absolute;
        flex-direction: column;
        align-items: flex-end;
        right: 2.2rem;
        span {
          font-size: 1.25rem;
          color: #7e7e7e;
          &.selectAll {
            color: #ff7e79;
            font-size: 1.3rem;
          }
        }
      }
    }
  }
  .bankCard {
    margin-top: 2rem;
    .flexCenter {
      padding: 1rem 0;
    }
    .bankCardList {
      flex-direction: column;
    }
    .noBandCard {
      flex-direction: column;
    }
    .bindCard {
      width: 90%;
      background: #fff;
      padding: 1rem 0;
      margin: 1rem auto;
      border-radius: 200px;
      font-size: 1.4rem;
      border: 1px solid #f2f2f2;
      .iconReset {
        margin-left: 1rem;
        width: 2rem;
        height: 2rem;
      }
    }
  }
  .widhdrawDescription {
    // background: #fff;
    padding: 2rem 1.5rem;
    margin-top: 2rem;
    line-height: 2.2rem;
    p {
      font-size: 1.3rem;
      &.p0 {
        font-size: 1.4rem;
      }
      &.p1 {
        color: @themeFontColor;
      }
    }
  }
}
</style>