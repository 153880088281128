<template>
  <div id="add-methods">
    <van-nav-bar title="设置提现账号" left-arrow @click-left="goLastView" />
    <van-form style="marginTop:1rem">
      <!-- :rules="[{ required: true, message: '请填写真实姓名' }]" -->
      <van-field v-model="set_form.alipay_account" type="支付宝账户" name="支付宝账户" label="支付宝账户" placeholder="请输入支付宝账户" />
      <van-field v-model="set_form.name" name="用户名" label="真实姓名" placeholder="请输入真实姓名" />
      <van-field v-model="set_form.id_card" type="身份证号" name="身份证号" label="身份证号" placeholder="请输入身份证号" />
      <div style="margin:46px 16px">
        <van-button color="linear-gradient(to right, #ff6034, #ee0a24)" block round @click="addAliPay">
          确定
        </van-button>
      </div>
    </van-form>
    <div class="tip">
      <p>绑定事项:</p>
      <p>1.提现账户必须与身份证姓名一致</p>
      <p>2.请输入支付宝账户信息用于金额提现，支付宝账户与姓名为您在支付宝登记的信息，请再三确认提现信息的准确性，否则提现失败</p>
    </div>
  </div>
</template>
<script>
import BankCard from "@/components/Mine/wallet/BankCard.vue";
export default {
  data() {
    return {
      set_form: {
        alipay_account: "",
        name: "",
        id_card: "",
      },
    };
  },
  components: {
    BankCard,
  },
  created() {},
  methods: {
    goLastView() {
      this.$emit("goLastView", "Main");
    },
    addAliPay() {
      if (!this.set_form.alipay_account) {
        this.$toast("请填写支付宝账号");
      } else if (!this.set_form.name) {
        this.$toast("请填写支付宝姓名");
      } else {
        this.$http
          .post("/api/v2/worker/withdraw/alipay/account", this.set_form)
          .then((res) => {
            this.$emit("temp-link-main",false);
            this.$toast.success(res.data.message);
          })
          .catch((err) => {
            this.$toast(err.error);
          });
      }
    },
  },
};
</script>

<style lang="less">
#add-methods {
  .tip {
    padding: 0 2rem 2rem 2rem;
    p {
      font-size: 1.2rem;
      margin: 1rem 0;
    }
    p:nth-child(1) {
      font-size: 1.3rem;
    }
  }
}
</style>