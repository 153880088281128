<template>
  <div class="bankCardItem bankType1" :style="`background-color: ${bankCardColor}`" @click="selectBankCard">
    <div class="bankCardItemBag">
      <div class="bankLogo" :style="`background: #fff url('${bankCardObj.bank_logo}') no-repeat  9px 0 / cover`"></div>
      <div class="bankType">
        <p>{{bankCardObj.bank_name}}</p>
        <p class="bankHold">卡类型 {{bankCardObj.card_type}}</p>
      </div>
      <div class="bankNum">
        {{bankCardObj.bank_card_number}}
      </div>
    </div>
    <span class="selected" v-show="bankCardObj.select">
      &#xe645;
    </span>
  </div>
</template>
<script>
export default {
  props: {
    bankCardObj: {
      type: Object,
      default() {
        return {};
      },
    },
    bankCardColor: {
      type: String,
      default: "",
    },
    bankCardUrl: {
      type: String,
      default: "",
    },
    bankCardHold: {
      type: String,
      default: "",
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: false,
      flag: true,
    };
  },
  watch: {
    bankCardObj(i, o) {
      console.log(i, o);
    },
  },
  methods: {
    selectBankCard(index) {
      this.$emit("slectBankObj", this.bankCardObj);
    },
  },
};
</script>
<style lang="less">
.bankCardItem {
  margin: 0.8rem 0;
  width: 100%;
  border-radius: 8px;
  // background-image: linear-gradient(to right, #fe9b2c, #ff7b03);
  position: relative;
  &.bankType1 {
    background-image: linear-gradient(to right, #fe9b2c, #ff7b03);
  }
  .selected {
    font-family: iconfont;
    font-size: 3rem;
    position: absolute;
    right: 2rem;
    bottom: 0;
    color: #fff;
  }
  .bankCardItemBag {
    display: flex;
    justify-content: space-between;
    padding: 2rem 1rem;
    color: #fff;
    .bankLogo {
      width: 4rem;
      height: 4rem;
      border-radius: 100px;
    }
    .bankType {
      font-size: 1.4rem;
      p {
        padding-bottom: 0.5rem;
        &.bankHold {
          font-size: 1.3rem;
        }
      }
    }
    .bankNum {
      font-size: 1.3rem;
    }
  }
}
</style>
