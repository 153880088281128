<template>
  <div id="withdraw-methods" v-if="methods_add_type == 'Main'">

    <van-nav-bar title="提现方式" right-text="" left-arrow @click-left="goMain" scrollable />
    <!-- <van-notice-bar mode="closeable">提现账户不能随意修改，如需修改提现账户,请在添加提现账户7天后进行修改</van-notice-bar> -->
    <loading type='2' v-if="loading_show" />
    <div class="methods-list" v-else>
      <div class="methods-item" v-for="(item,index) in alipay_accounts_list" :key="index" @click="selectPayMethod(item)">
        <div class="left">
          <svg class="icon icon-rerset-withdraw-methods" aria-hidden="true">
            <use :xlink:href="item.icon"></use>
          </svg>
          <p class="type-name">{{item.name}}</p>
        </div>
        <div class="type-info">{{item.alipay_account}}</div>
        <div @click.stop="deleteMethods(item.id)">
          <van-icon name="delete-o" size="2.0rem" />
        </div>
      </div>
      <div class="methods-item" v-if="wechat_account == 0">
        <div class="left">
          <svg class="icon icon-rerset-withdraw-methods" aria-hidden="true">
            <use xlink:href="#icon-weixin1"></use>
          </svg>
          <p class="type-name">微信</p>
        </div>
        <div class="type-info"></div>
      </div>
    </div>
    <div class="add-methods  flexCenter" @click="add_withdraw_show = true">
      添加提现账户
      <svg class="icon inon-reset-add-withdraw " aria-hidden="true">
        <use xlink:href="#icon-add"></use>
      </svg>
    </div>
    <p class="tip">
      注意：提现账户不能随意修改，如需修改提现账户,请在添加提现账户7天后进行修改
    </p>
    <van-popup v-model="add_withdraw_show" round position="bottom" :style="{ height: '30%',padding:'50px 0', positio:'absulate' }" closeable>
      <p class="add_title ">请选择提现方式</p>
      <van-cell :value="`添加${item.name}账户`" v-for="item in with_draw_list" :key="item.id" @click="selectedMethods(item)" clickable />
    </van-popup>
  </div>
  <widthDrawAliPay v-else-if="methods_add_type == 'Alipay'" @goLastView="goLastView" @temp-link-main="tempLinkView" />
</template>
<script>
/**
 * 选择提现方式组件
 * 通过methods_add_type: Main -> ’首页‘； 'BankCard' -> '银行卡'； ’WeChat‘ -> ’微信‘； ’Alipay‘ -> ’支付宝‘
 */
import widthDrawAliPay from "@/components/Mine/wallet/width-draw/add-methods/AliPay";
export default {
  data() {
    return {
      loading_show: true,
      methods_add_type: "Main",
      add_withdraw_show: false,
      bindWithdrawList: [],
      bank_cards: [],
      alipay_accounts_list: [],
      bank_cards_list: [],
      wechat_account: 1,
      with_draw_list: [
        {
          id: 0,
          name: "银行卡-待开发",
          icon: "#icon--",
          type: "BankCard",
        },
        {
          id: 1,
          name: "微信-待开发",
          icon: "#icon-weixin1",
          type: "WeChat",
        },
        {
          id: 2,
          name: "支付宝",
          icon: "#icon-zhifubao1",
          type: "Alipay",
        },
      ],
    };
  },
  components: {
    widthDrawAliPay,
  },
  created() {
    this.getBindMethods();
  },
  methods: {
    selectedMethods(item) {
      console.log(item);
      this.methods_add_type = item.type;
    },
    goMain() {
      this.$emit("goMain");
    },
    goLastView(e) {
      console.log(e);
      this.methods_add_type = e;
      this.add_withdraw_show = false;
    },
    getBindMethods() {
      this.loading_show = true;
      this.$http.get("/api/v2/worker/withdraw/accounts").then((res) => {
        this.loading_show = false;
        console.log("可提现的账号单", res);
        let _arr = res.data.result;
        this.alipay_accounts_list = res.data.result.alipay_accounts;
        this.bank_cards_list = res.data.result.bank_cards;
        this.alipay_accounts_list.forEach((item) => {
          item["type"] = 2;
          item["icon"] = "#icon-zhifubao1";
          item["name"] = "支付宝";
        });
        this.bank_cards_list.forEach((item) => {
          item["type"] = 0;
          item["icon"] = "#icon--";
        });
        console.log(this.bindWithdrawList);
      });
    },
    tempLinkView() {
      this.methods_add_type = "Main";
      this.add_withdraw_show = false;
      this.getBindMethods();
    },
    selectPayMethod(item) {
      let temp_result = {
        selected: item,
        temp_link_main: "main",
      };
      this.$emit("selected", temp_result);
    },
    deleteMethods(id) {
      this.$dialog
        .confirm({
          title: "确认删除此提现方式？",
          message: "",
          confirmButtonText: "确认删除",
        })
        .then(() => {
          this.$http
            .delete(`/api/v2/worker/withdraw/alipay/account/${id} `)
            .then((res) => {
              this.$toast.success(res.data.message);
              this.getBindMethods();
            })
            .catch((err) => {
              this.$toast.fail(err.error);
            });
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="less">
#withdraw-methods {
  .icon-rerset-withdraw-methods {
    width: 4rem;
    height: 4rem;
  }
  .inon-reset-add-withdraw {
    margin-left: 1rem;
    width: 2rem;
    height: 2rem;
  }
  .methods-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    padding: 0.5rem 1rem;
    margin: 1rem 0;
    .left {
      display: flex;
      align-items: center;
    }
    .type-name {
      font-size: 1.45rem;
      margin-left: 2rem;
    }
    .type-info {
      font-size: 1.3rem;
    }
  }

  .add-methods {
    width: 90%;
    background: #fff;
    padding: 1rem 0;
    margin: 5rem auto;
    border-radius: 200px;
    font-size: 1.4rem;
  }
  .tip {
    margin: 4rem;
    font-size: 1.3rem;
  }
  .add_title {
    font-size: 1.4rem;
    font-weight: 600;
    position: relative;
    top: -2.3rem;
    text-align: center;
  }
}
</style>